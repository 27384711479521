<template>
  <img :class="classes" :src="image" />
</template>

<script>
export default {
  name: 'ImageGetter',
  props: {
    url: String,
    classes: String,
    default: String
  },
  data() {
    return {
      image: '',
      loading: true
    };
  },
  mounted() {
    this.getImage();
  },
  methods: {
    getImage() {
      const url = this.url;
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.loading = false;
        this.image = url;
      };
      img.onerror = () => {
        this.loading = false;
        this.image = this.default;
      };
    }
  }
};
</script>

<style></style>
