<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        key="is-loading"
        v-if="isLoading"
        :class="{ 'absolute inset-0 bg-white': isFull }"
      >
        <throbber size="lg" color="light" />
      </div>
      <div v-else key="loading-content" class="flex flex-grow ">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Throbber from '@/components/Throbber';

export default {
  props: {
    isLoading: {
      type: Boolean
    },
    isFull: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Throbber
  }
};
</script>

<style lang="css" scoped></style>
